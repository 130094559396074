import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage = 1, mySubpage = 4 }) => {
  const questionNumber = 3
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"ONE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"Which of the following is an example of informal time?"}
        answers={["Drills during practice", "Games", "The bus ride to a game"]}
        correctAnswerNumber={2}
        answerDetail={
          "In sports, there are two crucial environments where girls build relationships: FORMAL TIME and INFORMAL TIME. Formal time is planned time. Structured time. Activities, games, and drills. Informal time is our down time. Our in-between time."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 1, 4)
